// src/utils/utils.js
export function convertToEnglish(persianNumber) {
    const persianToEnglishMap = {
      '۰': '0',
      '۱': '1',
      '۲': '2',
      '۳': '3',
      '۴': '4',
      '۵': '5',
      '۶': '6',
      '۷': '7',
      '۸': '8',
      '۹': '9'
    };
  
    let englishValue = '';
    for (let char of persianNumber) {
      englishValue += persianToEnglishMap[char] || char;
    }
    return englishValue;
  }
  