// src/directives/convertToEnglish.js
import { convertToEnglish } from '@/utils/utils';

export default {
  beforeMount(el) {
    el.addEventListener('input', () => {
      el.value = convertToEnglish(el.value).replace(/\D/g, ''); // Only keep numeric characters
      el.dispatchEvent(new Event('input')); // Ensures v-model gets updated
    });
  }
};
